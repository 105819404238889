<template>

    <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
            A.
          <span class="badge badge-danger ">{{numero_asignaciones}}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right" style="height: auto; max-height: 200px; overflow-x: hidden; min-width: 400px;">
          <div class="dropdown-divider"></div>
          <ListaAsigaciones :asignaciones="asignaciones"/>
        </div>
    </li>
</template>
<script>
import ListaAsigaciones from './AsignacionesLista.vue';


export default ({
    
    components:{
        ListaAsigaciones
    },
    props: ['asignaciones','numero_asignaciones'],
    data() {
        return {
          
        }
    },
    methods: {
   
    },
    mounted() {
    }
})
</script>
